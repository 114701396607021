import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useSession } from 'next-auth/react';

import { cn, useScroll } from '@frontend/shared-utils';
import { AdminDropdown } from '../navigation/admin-controls/AdminDropdown';
import { ImageWithFallback } from '@frontend/shared-ui-kit/image';
import { Icon } from '@frontend/shared-ui-kit/icon';
import { useRouter } from 'next/router';
import { useActions, useAppState } from '@frontend/howl-web-app/overmind';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { AccountDropdown } from '../navigation/account-dropdown/AccountDropdown';

import { Button } from '@frontend/shared-ui-kit/v2/button';
import { Flag } from '../../flag/flag';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@frontend/shared-ui-kit/v2/dialog';
import { BrickBanner } from '@frontend/howl-web-app/shared/components';

export const PortalMobileNavigationHeader = () => {
  const router = useRouter();
  const { setCreatorSidebarOpen } = useActions().ui;
  const publisherId = router.query.publisherId;
  const { creatorSidebarOpen } = useAppState().ui;
  const scroll = useScroll();
  const [navClassList, setNavClassList] = useState('');
  const {
    openGlobalLinkCreationModal,
    setGlobalLinkProductAndOpenModal,
    setGlobalLinkUrlAndOpenModal,
  } = useActions().link;
  const { globalLinkCreationModalOpen } = useAppState().link;

  // update classList of nav on scroll
  useEffect(() => {
    let navPositionClass = '-top-0 sm:top-0';

    if (scroll.y > 150 && scroll.y - scroll.lastY > 0 && !creatorSidebarOpen) {
      navPositionClass = '-top-16 sm:top-0 ';
    }

    setNavClassList(navPositionClass);
  }, [scroll.y, scroll.lastY, creatorSidebarOpen]);

  return (
    <>
      <div
        className={cn('fixed w-full h-32 z-40 transition-all', navClassList)}
      >
        <div
          className={cn(
            'flex justify-between w-full bg-white border-opacity-20',
          )}
        >
          <div className="flex gap-x-3 items-center">
            <div
              className="ml-3 flex items-center"
              onClick={() => setCreatorSidebarOpen(!creatorSidebarOpen)}
            >
              {creatorSidebarOpen ? (
                <XIcon color="black" className="h-6 w-6" />
              ) : (
                <Icon
                  className="ml-1"
                  iconName={'menu'}
                  color="black"
                  size={20}
                />
              )}
            </div>
            <button
              className="relative my-2 overflow-hidden rounded-sm xl:rounded-lg w-[32px] h-[32px] outline-none focus:bg-interaction-secondary-pressed transition"
              onClick={() => router.push(`/publisher/${publisherId}/dashboard`)}
              data-cy="nav-brand-logo"
            >
              <ImageWithFallback
                src="/images/planet-howl.png"
                alt="brand image"
                className="object-cover w-full h-full"
              />
            </button>
          </div>

          <div className="flex items-center gap-2 px-4">
            <div className="">
              <AdminDropdown showPortalNavigation />
            </div>

            <AccountDropdown showPortalNavigation />
          </div>
        </div>
      </div>
    </>
  );
};
