import { AdminDropdown } from '../navigation/admin-controls/AdminDropdown';
import { AccountDropdown } from '../navigation/account-dropdown/AccountDropdown';
import { ImageWithFallback } from '@frontend/shared-ui-kit/image';
import { PortalNavigationMenu } from './PortalNavigationMenu';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { Button } from '@frontend/shared-ui-kit/v2/button/button';
import { PlusIcon } from '@heroicons/react/outline';
import { useActions, useAppState } from '@frontend/howl-web-app/overmind';
import { Flag } from '../../flag/flag';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@frontend/shared-ui-kit/v2/dialog/dialog';
import { CreatorGlobalLinkCreationModal } from '../../creator/global-link-creation/CreatorGlobalLinkCreationModal';
import { CreatorGlobalLinkURLCheckWrapper } from '../../creator/global-link-creation';
import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BrickBanner } from '@frontend/howl-web-app/shared/components';

export const PortalNavigationHeader = () => {
  const router = useRouter();
  const publisherId = router.query.publisherId;
  const {
    openGlobalLinkCreationModal,
    setGlobalLinkProductAndOpenModal,
    setGlobalLinkUrlAndOpenModal,
  } = useActions().link;

  const flags = useFlags();

  const { globalLinkCreationModalOpen } = useAppState().link;
  const { data: session } = useSession();
  const email_verified = session?.user?.email_verified;
  const clearHash = () => {
    router.replace(router.pathname, router.asPath.split('#')[0], {
      scroll: false,
      shallow: true,
    });
  };
  return (
    <>
      <div className="z-40 border border-b border-opacity-20 fixed w-full top-0 bg-white h-30">
        <div className={'flex items-center justify-between w-full'}>
          <div style={{ flexGrow: 1 }} className="flex basis-1 items-center">
            <button
              className="relative my-2 mx-5 overflow-hidden rounded-sm xl:rounded-lg w-[32px] h-[32px] outline-none focus:bg-interaction-secondary-pressed transition"
              onClick={() => router.push(`/publisher/${publisherId}/dashboard`)}
              data-cy="nav-brand-logo"
            >
              <ImageWithFallback
                src="/images/planet-howl.png"
                alt="brand image"
                className="object-cover w-full h-full"
              />
            </button>
            {email_verified && (
              <>
                {!flags['holidayClubhouse'] && (
                  <Link
                    href={`/publisher/${publisherId}/refer-and-earn`}
                    passHref
                    className="basis-auto text-text-link text-sm text-center font-medium bg-interaction-secondary hover:bg-interaction-secondary-hover focus:bg-interaction-pressed transition px-4 py-2 rounded-lg"
                    data-cy="/refer-and-earn"
                  >
                    My Referrals
                  </Link>
                )}
                <Flag name="holidayClubhouse">
                  <Link
                    href={`/publisher/${publisherId}/holiday-clubhouse`}
                    className="flex gap-3 px-4 py-2 bg-[#003D0A] rounded-full justify-center items-center"
                  >
                    <div className="bg-green-950 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        className="w-6 h-6 text-[#B6FFC3]"
                        data-src="/images/icons/snowflake-2.svg"
                      >
                        <path
                          d="M17.2528 0L14.7135 0.00842036L14.7496 10.8813L17.2888 10.8729L17.2528 0Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M18.599 6.58036L21.0283 0.715527L23.3742 1.68724L20.9449 7.55208L18.599 6.58036Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M8.79688 1.69364L11.1428 0.721924L13.5721 6.58676L11.2262 7.55848L8.79688 1.69364Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M24.5208 11.1204L30.3857 8.69111L31.3574 11.037L25.4926 13.4663L24.5208 11.1204Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M0.828125 20.942L6.693 18.5127L7.66472 20.8587L1.79985 23.2879L0.828125 20.942Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M25.4873 18.5023L24.5156 20.8483L30.3805 23.2776L31.3522 20.9316L25.4873 18.5023Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M0.828125 11.0406L1.79985 8.69471L7.66472 11.124L6.693 13.4699L0.828125 11.0406Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M20.9605 24.4159L18.6146 25.3877L21.0439 31.2525L23.3898 30.2808L20.9605 24.4159Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M11.221 24.4154L8.79167 30.2802L11.1376 31.2519L13.5669 25.3871L11.221 24.4154Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M14.7135 21.1269L17.2528 21.1185L17.2888 31.9914L14.7496 31.9998L14.7135 21.1269Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M10.873 14.7129L0 14.749L0.00842041 17.2882L10.8814 17.2521L10.873 14.7129Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M21.1198 14.7503L31.9927 14.7142L32.0012 17.2534L21.1282 17.2895L21.1198 14.7503Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M26.4076 3.77738L18.7448 11.4912L20.5462 13.2807L28.2091 5.56692L26.4076 3.77738Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M3.8125 26.4267L11.4753 18.7129L13.2768 20.5025L5.61396 28.2163L3.8125 26.4267Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M5.58121 3.79519L3.79167 5.59664L11.5055 13.2594L13.295 11.458L5.58121 3.79519Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M18.7292 20.5358L20.5187 18.7343L28.2325 26.3971L26.443 28.1986L18.7292 20.5358Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    <div className="text-white text-[14px] font-medium leading-normal">
                      Holiday Clubhouse
                    </div>
                  </Link>
                </Flag>
              </>
            )}
          </div>

          <div className="basis-4" style={{ flexGrow: 1 }}>
            <PortalNavigationMenu />
          </div>

          <div
            className="basis-1 flex items-center gap-2 justify-end mr-8"
            style={{ flexGrow: 1 }}
          >
            <AdminDropdown showPortalNavigation />
            <AccountDropdown showPortalNavigation />
          </div>
        </div>
      </div>
    </>
  );
};
