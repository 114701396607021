import React, { ReactNode } from 'react';
import Link from 'next/link';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@frontend/shared-ui-kit/v2/navigation-menu';
import {
  useAppState,
  useActions,
  RoleNames,
} from '@frontend/howl-web-app/overmind';
import { Icon } from '@frontend/shared-ui-kit/icon';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { cn, useMediaQuery } from '@frontend/shared-utils';
import { useSession } from 'next-auth/react';

import { Flag } from '../../flag/flag';

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (chilren: ReactNode) => ReactNode;
  children: ReactNode;
}) => (condition ? wrapper(children) : children);

type ListItemProps = {
  dataCy?: string;
  dataRef?: string;
};
const ListItem = React.forwardRef<
  React.ElementRef<'a'>,
  React.ComponentPropsWithoutRef<'a'> & ListItemProps
>(({ className, children, dataCy, dataRef, ...props }, ref) => {
  const { setCreatorSidebarOpen } = useActions().ui;
  const { href } = props;
  return href ? (
    <li>
      <NavigationMenuLink asChild onSelect={() => setCreatorSidebarOpen(false)}>
        <Link
          ref={ref}
          className={cn(
            'block select-none space-y-1 rounded-md leading-none no-underline outline-none transition-colors hover:bg-interaction-selected  focus:bg-interaction-selected',
            className,
          )}
          href={href}
          data-cy={dataCy}
          data-ref={dataRef || undefined}
        >
          <div className="text-sm font-medium leading-none rounded-lg p-3 text-black">
            {children}
          </div>
        </Link>
      </NavigationMenuLink>
    </li>
  ) : null;
});
ListItem.displayName = 'ListItem';

export const PortalNavigationMenu = () => {
  const { selectedOrg, selectedOrgId } = useAppState().organization;
  const { role } = useAppState().account;
  const { data: sessionData } = useSession();
  const router = useRouter();
  const { setCreatorSidebarOpen } = useActions().ui;

  const email_verified = sessionData?.user?.email_verified;
  const listClass = 'pl-3 ml-2 mb-2';
  const isMobile = useMediaQuery('(max-width: 640px)');
  const isTablet = useMediaQuery('(max-width: 1280px)');
  const { displayAlertIcon } = useAppState().publisher;
  const flags = useFlags();

  const getStripeSetupCompleted = () => {
    if (!selectedOrgId || !sessionData || sessionData.user?.role === 'admin')
      return false;
    const bamxPerms = sessionData && sessionData.user?.bamx_perms;
    const stripeSetupComplete =
      bamxPerms.publishers[selectedOrgId] &&
      bamxPerms.publishers[selectedOrgId].stripe_setup_complete;

    return stripeSetupComplete;
  };

  const badgeAlertIcon = displayAlertIcon ? (
    <Icon
      iconName="filledAlert"
      size={12}
      className="inline-flex absolute top-4 md:top-2 left-5 border border-white rounded-full"
      dataCy="nav-payment-setup-alert"
    />
  ) : null;

  return (
    <>
      <NavigationMenu
        className="justify-center w-full md:items-center md:w-auto"
        orientation={isMobile ? 'vertical' : 'horizontal'}
      >
        <NavigationMenuList
          className={
            'flex flex-col md:flex-row w-screen md:w-auto list-style-none items-start p-2 md:p-0 md:space-x-4'
          }
        >
          <NavigationMenuItem className="w-full md:w-auto">
            <NavigationMenuLink
              onSelect={() => setCreatorSidebarOpen(false)}
              className={classNames(navigationMenuTriggerStyle(), {
                'bg-primary-10 sm:focus:bg-primary-10 text-primary-400':
                  router.pathname.includes('dashboard'),
                'w-full !justify-start': isMobile,
              })}
              asChild
            >
              <Link
                href={`/publisher/${selectedOrgId}/dashboard`}
                className="w-full"
                data-cy="/dashboard"
                data-ref="nav-home"
              >
                <Icon className="mr-2 md:mr-0 xl:mr-2" iconName="portalHome" />
                <span className="inline md:hidden xl:inline">Home</span>
              </Link>
            </NavigationMenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem className="w-full md:w-auto">
            <NavigationMenuTrigger
              className={classNames(navigationMenuTriggerStyle(), {
                'bg-primary-10 sm:focus:bg-primary-10 text-primary-400':
                  router.pathname.includes('campaign-center'),
                'w-full !justify-between': isMobile,
              })}
              data-cy="discover-nav-toggle"
              data-ref="nav-discover"
            >
              <ConditionalWrapper
                condition={!isTablet}
                wrapper={(children: any) => (
                  <Link
                    href={`/publisher/${selectedOrgId}/campaign-center/brands`}
                    className="inline-flex w-full h-full"
                    data-cy="discover-nav-link"
                  >
                    {children}
                  </Link>
                )}
              >
                <div className="flex items-center">
                  <Icon className="mr-2 md:mr-0 xl:mr-2" iconName="handbag" />
                  <span className="inline md:hidden xl:inline">Discover</span>
                </div>
              </ConditionalWrapper>

              <Icon
                className="md:hidden mr-2 transition duration-200 group-data-[state=open]:rotate-180"
                iconName="chevronDown"
                color="black"
                size={20}
              />
            </NavigationMenuTrigger>
            <NavigationMenuContent
              className={classNames('md:absolute', {
                'border-0 shadow-none': isMobile,
              })}
            >
              <ul
                className={classNames({
                  'p-2 min-w-[224px]': !isMobile,
                  [listClass]: isMobile,
                })}
              >
                <ListItem
                  href={`/publisher/${selectedOrgId}/campaign-center/brands`}
                  dataCy="/campaign-center/brands"
                  dataRef="nav-brands"
                >
                  Brands
                </ListItem>
                <ListItem
                  href={`/publisher/${selectedOrgId}/campaign-center/campaigns`}
                  dataCy="/campaign-center/campaigns"
                  dataRef="nav-campaigns"
                >
                  Campaigns
                </ListItem>
                <ListItem
                  href={`/publisher/${selectedOrgId}/campaign-center/campaigns?filter=sale`}
                  dataCy="/campaign-center/campaigns?filter=sale"
                  className="before:block before:absolute before:-left-2  before:h-[90%] before:bottom-0 before:border-l before:border-border-strong  before: ml-5 relative"
                  dataRef="nav-sale"
                >
                  Sales
                </ListItem>
                <ListItem
                  href={`/publisher/${selectedOrgId}/campaign-center/campaigns?filter=launches`}
                  dataCy="/campaign-center/campaigns?filter=launches"
                  className="before:block before:absolute before:-left-2  before:h-full before:top-0 before:border-l before:border-border-strong  before: ml-5 relative"
                  dataRef="nav-brands"
                >
                  Launches
                </ListItem>
                <ListItem
                  href={`/publisher/${selectedOrgId}/campaign-center/campaigns?filter=gifting`}
                  dataCy="/campaign-center/campaigns?filter=gifting"
                  className="before:block before:absolute before:-left-2 before:h-full before:top-0 before:border-l before:border-border-strong  before: ml-5 relative"
                  dataRef="nav-gifting"
                >
                  Gifting
                </ListItem>
                {flags.favoritedBrandsCampaignTargeting && (
                  <ListItem
                    href={`/publisher/${selectedOrgId}/campaign-center/campaigns?filter=favorited_brands`}
                    dataCy="/campaign-center/campaigns?filter=favorited_brands"
                    className="before:block before:absolute before:-left-2 before:h-[90%] before:top-0 before:border-l before:border-border-strong  before: ml-5 relative"
                    dataRef="nav-favorited_brands"
                  >
                    Favorited Brands
                  </ListItem>
                )}
                <ListItem
                  href={`/publisher/${selectedOrgId}/campaign-center/calendar`}
                  dataCy="/campaign-center/calendar"
                  dataRef="nav-calendar"
                >
                  My Calendar
                </ListItem>
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          <NavigationMenuItem className="w-full md:w-auto">
            <NavigationMenuTrigger
              className={classNames(navigationMenuTriggerStyle(), {
                'bg-primary-10 sm:focus:bg-primary-10 text-primary-400':
                  router.pathname.includes('links/my-links') ||
                  router.pathname.includes(`[publisherId]/storefront`),
                'w-full !justify-between': isMobile,
              })}
              data-cy="my-products-nav-toggle"
              data-ref="nav-myproducts"
            >
              <ConditionalWrapper
                condition={!isTablet}
                wrapper={(children: any) => (
                  <Link
                    href={
                      selectedOrg.publisher_type === 2
                        ? `/publisher/${selectedOrgId}/links/my-links`
                        : ''
                    }
                    className="w-full h-full"
                    data-cy="my-products-nav-link"
                    passHref
                  >
                    {children}
                  </Link>
                )}
              >
                <div className="flex items-center">
                  <Icon className="mr-2 md:mr-0 xl:mr-2" iconName="link" />
                  <span className="inline md:hidden xl:inline">
                    My Products
                  </span>
                </div>
              </ConditionalWrapper>

              <Icon
                className="md:hidden mr-2 transition duration-200 group-data-[state=open]:rotate-180"
                iconName="chevronDown"
                color="black"
                size={20}
              />
            </NavigationMenuTrigger>
            <NavigationMenuContent
              className={classNames('md:absolute', {
                'border-0 shadow-none': isMobile,
              })}
            >
              <ul
                className={classNames({
                  'p-2 min-w-[224px]': !isMobile,
                  [listClass]: isMobile,
                })}
              >
                {selectedOrg?.publisher_type === 2 && (
                  <ListItem
                    href={`/publisher/${selectedOrgId}/links/my-links`}
                    dataCy="/links/my-links"
                    dataRef="nav-mylinks"
                  >
                    My Links
                  </ListItem>
                )}
                {/*<ListItem
                  href={`/publisher/${selectedOrgId}/links/create-links`}
                  dataCy="/links/create-links"
                  dataRef="nav-createlinks"
                >
                  Create Links
                </ListItem>*/}
                {selectedOrg?.publisher_type === 2 && (
                  <ListItem
                    href={`/publisher/${selectedOrgId}/storefront`}
                    dataCy="/links/storefront"
                    dataRef="nav-storefront"
                  >
                    Storefront
                  </ListItem>
                )}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>

          {selectedOrg.publisher_type === 2 &&
          role &&
          [
            RoleNames.ACCOUNT_OWNER,
            RoleNames.BILLING_ADMIN,
            RoleNames.TEAM_ADMIN,
          ].includes(role) ? (
            <NavigationMenuItem className="w-full md:w-auto">
              <Link
                href={`/publisher/${selectedOrgId}/orders`}
                legacyBehavior
                passHref
              >
                <NavigationMenuLink
                  onSelect={() => setCreatorSidebarOpen(false)}
                  className={classNames(navigationMenuTriggerStyle(), {
                    'bg-primary-10 sm:focus:bg-primary-10 text-primary-400':
                      router.pathname.includes('orders'),
                    'w-full !justify-start': isMobile,
                  })}
                  data-cy="/orders"
                  data-ref="nav-orders"
                >
                  <Icon
                    className="mr-2 md:mr-0 xl:mr-2"
                    iconName="portalShoppingCart"
                  />
                  <span className="inline md:hidden xl:inline">
                    Orders Feed
                  </span>
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          ) : null}
          {role &&
          [
            RoleNames.ACCOUNT_OWNER,
            RoleNames.BILLING_ADMIN,
            RoleNames.TEAM_ADMIN,
          ].includes(role) ? (
            <NavigationMenuItem className="w-full md:w-auto">
              <NavigationMenuTrigger
                className={classNames(navigationMenuTriggerStyle(), {
                  'bg-primary-10 sm:focus:bg-primary-10 text-primary-400':
                    router.pathname.includes('analytics'),
                  'w-full !justify-between': isMobile,
                })}
                data-cy="analytics-nav-toggle"
                data-ref="nav-analytics"
              >
                <ConditionalWrapper
                  condition={!isTablet}
                  wrapper={(children: any) => (
                    <Link
                      href={`/publisher/${selectedOrgId}/analytics/overview`}
                      aria-disabled={isMobile}
                      data-cy="analytics-nav-link"
                      className="w-full h-full"
                    >
                      {children}
                    </Link>
                  )}
                >
                  <div className="flex items-center">
                    <Icon
                      className="mr-2 md:mr-0 xl:mr-2"
                      iconName="chartBarVertical"
                    />
                    <span className="inline md:hidden xl:inline">
                      Analytics
                    </span>
                  </div>
                </ConditionalWrapper>

                <Icon
                  className="md:hidden mr-2 transition duration-200 group-data-[state=open]:rotate-180"
                  iconName="chevronDown"
                  color="black"
                  size={20}
                />
              </NavigationMenuTrigger>
              <NavigationMenuContent
                className={classNames('md:absolute', {
                  'border-0 shadow-none': isMobile,
                })}
              >
                <ul
                  className={classNames({
                    'p-2 w-full min-w-[224px]': !isMobile,
                    [listClass]: isMobile,
                  })}
                >
                  <ListItem
                    href={`/publisher/${selectedOrgId}/analytics/overview`}
                    dataCy="/analytics/overview"
                    dataRef="nav-aoverview"
                  >
                    Overview
                  </ListItem>
                  <ListItem
                    href={`/publisher/${selectedOrgId}/analytics/brands`}
                    dataCy="/analytics/brands"
                    dataRef="nav-abrands"
                  >
                    Brands
                  </ListItem>
                  <ListItem
                    href={`/publisher/${selectedOrgId}/analytics/campaigns`}
                    dataCy="/analytics/campaigns"
                    dataRef="nav-acampaigns"
                  >
                    Campaigns
                  </ListItem>
                  <ListItem
                    href={`/publisher/${selectedOrgId}/analytics/posts`}
                    dataCy="/analytics/posts"
                    dataRef="nav-aposts"
                  >
                    Posts
                  </ListItem>
                  <ListItem
                    href={`/publisher/${selectedOrgId}/analytics/links`}
                    dataCy="/analytics/links"
                    dataRef="nav-alinks"
                  >
                    Links
                  </ListItem>
                  {selectedOrg.publisher_type === 2 ? (
                    <ListItem
                      href={`/publisher/${selectedOrgId}/analytics/storefront`}
                      dataCy="/analytics/storefront"
                      dataRef="nav-astorefront"
                    >
                      Storefront
                    </ListItem>
                  ) : null}

                  {selectedOrg.publisher_type === 1 ? (
                    <ListItem
                      href={`/publisher/${selectedOrgId}/analytics/products`}
                      dataCy="/analytics/products"
                      dataRef="nav-aproducts"
                    >
                      Products
                    </ListItem>
                  ) : null}
                </ul>
              </NavigationMenuContent>
            </NavigationMenuItem>
          ) : null}

          {role &&
          [
            RoleNames.ACCOUNT_OWNER,
            RoleNames.BILLING_ADMIN,
            RoleNames.TEAM_ADMIN,
          ].includes(role) &&
          email_verified ? (
            <NavigationMenuItem className="w-full md:w-auto">
              <Link
                href={`/publisher/${selectedOrgId}/payments/${
                  getStripeSetupCompleted() ? 'payment-history' : 'earnings'
                }`}
                legacyBehavior
                passHref
                className="w-full"
              >
                <NavigationMenuLink
                  onSelect={() => setCreatorSidebarOpen(false)}
                  className={classNames(navigationMenuTriggerStyle(), {
                    'bg-primary-10 sm:focus:bg-primary-10 text-primary-400':
                      router.pathname.includes('payments'),
                    'w-full !justify-start': isMobile,
                  })}
                  data-cy="/payments/payment-history"
                  data-ref="nav-payments"
                >
                  {badgeAlertIcon}
                  <Icon
                    className="mr-2 md:mr-0 xl:mr-2"
                    iconName="portalCreditCard"
                    color="black"
                  />
                  <span className="inline md:hidden xl:inline">Payments</span>
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          ) : null}

          {isMobile && email_verified && (
            <Flag name="holidayClubhouse">
              <div className="w-full mt-2 flex">
                <Link
                  href={`/publisher/${router.query.publisherId}/holiday-clubhouse`}
                  className="flex gap-3 w-full px-4 py-3 bg-[#003D0A] rounded-lg justify-center items-center"
                  onClick={() => setCreatorSidebarOpen(false)}
                >
                  <div className="bg-green-950 rounded-full">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      className="w-8 h-8 text-[#B6FFC3]"
                      data-src="/images/icons/snowflake-2.svg"
                    >
                      <path
                        d="M17.2528 0L14.7135 0.00842036L14.7496 10.8813L17.2888 10.8729L17.2528 0Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M18.599 6.58036L21.0283 0.715527L23.3742 1.68724L20.9449 7.55208L18.599 6.58036Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M8.79688 1.69364L11.1428 0.721924L13.5721 6.58676L11.2262 7.55848L8.79688 1.69364Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M24.5208 11.1204L30.3857 8.69111L31.3574 11.037L25.4926 13.4663L24.5208 11.1204Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M0.828125 20.942L6.693 18.5127L7.66472 20.8587L1.79985 23.2879L0.828125 20.942Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M25.4873 18.5023L24.5156 20.8483L30.3805 23.2776L31.3522 20.9316L25.4873 18.5023Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M0.828125 11.0406L1.79985 8.69471L7.66472 11.124L6.693 13.4699L0.828125 11.0406Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M20.9605 24.4159L18.6146 25.3877L21.0439 31.2525L23.3898 30.2808L20.9605 24.4159Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M11.221 24.4154L8.79167 30.2802L11.1376 31.2519L13.5669 25.3871L11.221 24.4154Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M14.7135 21.1269L17.2528 21.1185L17.2888 31.9914L14.7496 31.9998L14.7135 21.1269Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M10.873 14.7129L0 14.749L0.00842041 17.2882L10.8814 17.2521L10.873 14.7129Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M21.1198 14.7503L31.9927 14.7142L32.0012 17.2534L21.1282 17.2895L21.1198 14.7503Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M26.4076 3.77738L18.7448 11.4912L20.5462 13.2807L28.2091 5.56692L26.4076 3.77738Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M3.8125 26.4267L11.4753 18.7129L13.2768 20.5025L5.61396 28.2163L3.8125 26.4267Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M5.58121 3.79519L3.79167 5.59664L11.5055 13.2594L13.295 11.458L5.58121 3.79519Z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M18.7292 20.5358L20.5187 18.7343L28.2325 26.3971L26.443 28.1986L18.7292 20.5358Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                  <div className="w-52 h-6 text-white text-base font-medium font-['Vulf Sans'] leading-normal">
                    Explore Holiday Clubhouse
                  </div>
                </Link>
              </div>
            </Flag>
          )}

          {isMobile && email_verified && (
            <div className="w-full mt-2 flex">
              <Link
                href={`/publisher/${selectedOrgId}/refer-and-earn`}
                passHref
                className="basis-auto text-text-link text-center text-sm font-medium bg-interaction-secondary hover:bg-interaction-secondary-hover focus:bg-interaction-pressed transition px-1 py-4 rounded-lg w-full"
                data-cy="/refer-and-earn"
                onClick={() => setCreatorSidebarOpen(false)}
              >
                My Referrals
              </Link>
            </div>
          )}
        </NavigationMenuList>
      </NavigationMenu>
    </>
  );
};
